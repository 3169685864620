import React from 'react'

import Layout from '../components/layout'
import Blurb from '../components/Blurb'
import SEO from '../components/SEO';

const RegistryPage = () => (
  <Layout>
    <SEO title="Registry"/>
    <Blurb>
      We already have plenty of stuff. Plus, as social scientists and David Brooks never tire of
      pointing out, the research shows that you gain more happiness from investing in experiences
      than in things. So we’d love for you to help us experience an amazing, relaxing honeymoon in
      Hawaii. We’ve set up a honeymoon registry at Zola for you to contribute to our Pacific overture.
    </Blurb>
    <Blurb>
      But if you’re dedicated to tangible gifts, that’s very understandable. We’re also registered
      at some of the usual places for some of the usual things.
    </Blurb>
    <Blurb>
      Finally, we know that this whole thing is an extravagance we’re extraordinarily lucky to be
      able to indulge in. The world is falling apart, and we’d be equally thrilled if you honored
      us by supporting organizations doing important, necessary work.
    </Blurb>

    <h2>EXPERIENCE</h2>

    <Blurb>
      <ul><li><a target="_blank" rel="noopener noreferrer" href="https://www.zola.com/registry/willandjesse">Honeymoon</a></li></ul>
    </Blurb>

    <h2>THINGS</h2>
    <Blurb>
      <ul>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.bloomingdales.com/registry/wedding/guest/?registryId=2397406">Bloomingdale’s</a></li>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.crateandbarrel.com/gift-registry/jesse-oxfeld-and-will-strasser/r5939124">Crate and Barrel</a></li>
        <li><a target="_blank" rel="noopener noreferrer" href="http://email.filson.com/t/l?ssid=33416&subscriber_id=bnwuousomilwupkqpnbcyziozwfdbdg&delivery_id=bkaajxuybaxxluxouzwsjarittshble&td=FenGfra-iRHZ9Sc6Rgrw5Qa2SeeKXawTMtXWtbYksqa1P0s7D7hMwWrtq9CeJf2VbTJN1aPdWs3qJK8NPMFVvvGsxYOvkj8PrW3hHXr2ETzSXykHp7dc8lQc7OvRExB5RnLbf8b3XOWir_k_IDWh5xv0a1hsfbYLnrKGebSKCWM-9fKyl6rmxiin9fMg0pdLbwp5ru4nebJjkOa6XONJwK1_vUshaYYVykYWA4mcX2fMbk7l4rG_UGQcwpmyGmEumDcRWo_eYwRZY">Filson</a></li>
      </ul>
    </Blurb>

    <h2>ORGANIZATIONS</h2>

    <Blurb>
      <ul>
        <li><a target="_blank" rel="noopener noreferrer" href="https://action.aclu.org/teamaclu/campaign/will-and-jesse">ACLU</a></li>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.lambdalegal.org/jesse-will">Lambda Legal</a></li>
      </ul>
    </Blurb>
  </Layout>
)

export default RegistryPage
